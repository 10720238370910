<template>
  <div class="steps flex">
    <router-link
      :class="{ active: isActive('Space') }"
      :to="`/editor/${id}/space`"
    >
      <img class="icon" src="@/assets/exhibit-step1.svg" alt="" />
      <img class="icon in" src="@/assets/exhibit-step1-active.svg" alt="" />

      <span class="text">
        <span>Step 1 </span>
        <span class="title">設定展間</span>
      </span>
    </router-link>

    <router-link
      class="ml-12"
      :class="{ active: isActive('Profile') }"
      :to="`/editor/${id}/profile`"
    >
      <img class="icon" src="@/assets/exhibit-step2.svg" alt="" />
      <img class="icon in" src="@/assets/exhibit-step2-active.svg" alt="" />
      <span class="text">
        <!-- <El-Tooltip
          v-model="hasUnRefreshed"
          :manual="true"
          :offset="40"
          effect="dark"
          content="標題或策展人已修改，請重新確認使用的圖片。"
          placement="bottom"
        > -->
        <span>Step 2 </span>
        <!-- </El-Tooltip> -->
        <span class="title">總說 / 主視覺海報</span>
      </span>
    </router-link>

    <a href="#" class="ml-12" @click.prevent="publish">
      <img class="inline-block" src="@/assets/exhibit-step3.svg" alt="" />
      <img class="icon in" src="@/assets/exhibit-step3-active.svg" alt="" />
      <span class="text">
        <span>Step 3</span>
        <span class="title">送出審核</span>
      </span>
    </a>
  </div>
</template>

<script>
import { inject } from 'vue';

import { useRoute } from 'vue-router';

// import { ElTooltip } from 'element-plus';
export default {
  // components: { ElTooltip },
  setup(props) {
    const exhibId = inject('exhibId');

    const route = useRoute();

    const isActive = (key) => {
      return route.name === `Editor.${key}`;
    };
    const publish = inject('publish');

    return {
      isActive,
      publish,
      id: exhibId,
    };
  },
};
</script>
<style lang="scss" scoped>
.steps {
  > a {
    @apply flex items-center text-sm;
    .icon {
      @apply inline-block;
      &.in {
        @apply hidden;
      }
    }
    .text {
      @apply ml-4;
      > span {
        @apply block;
        &.title {
          @apply font-bold;
        }
      }
    }
    &.active {
      @apply text-primary;
      .icon {
        @apply hidden;
        &.in {
          @apply inline-block;
        }
      }
    }
  }
}
</style>

<template>
  <div class="full flex flex-col">
    <App-Necker class="flex-none lg:flex hidden" v-if="!$state.isMobile">
      <template v-slot:title>
        <Editable-Input
          v-if="title"
          v-model:text="title"
          @update:text="updateTitle"
          :editable="isEditable"
        />
      </template>
      <template v-slot:right>
        <Steps v-if="isEditable" />
      </template>
    </App-Necker>

    <div
      class="flex-grow lg:block bg-white"
      v-if="!$state.isMobile && title && readed"
      :class="isFull ? fullSty : ''"
    >
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component"></component>
        </transition>
      </router-view>
      <!-- <Router-View /> -->
    </div>
    <div
      v-if="$state.isMobile"
      class="
        fixed
        top-0
        left-0
        w-screen
        h-screen
        pointer-events-none
        bg-gray-200
        flex
        justify-center
        items-center
      "
    >
      請使用桌上型電腦
    </div>
  </div>
</template>

<script>
import { toRefs, provide, onMounted, onBeforeUnmount } from 'vue';
import Steps from './_Steps.vue';
import EditableInput from '@/components/EditableInput.vue';
import useExhib from './useExhib';
import useSelection from './useSelection';
import useFull from '@/pages/index/components/useFull';
import { onBeforeRouteUpdate } from 'vue-router';

export default {
  components: { Steps, EditableInput },
  setup() {
    const { state, fetcher, updateTitle, publish } = useExhib();

    provide('exhibState', state);
    provide('exhibId', state.id);
    provide('publish', publish);

    const selection = useSelection({ id: state.id });
    provide('selection', selection);

    const { isFull, fullSty, toggleFull } = useFull();
    provide('full', { isFull, fullSty, toggleFull });

    onMounted(async () => {
      await fetcher();
      if (state.isEditable || state.isPreviewable) {
        selection.init();
      }
    });

    onBeforeRouteUpdate((to, from) => {
      console.log(isFull.value);
      if (isFull.value) {
        toggleFull();
      }
    });

    return {
      ...toRefs(state),
      updateTitle,
      readed: selection.readed,
      fullSty,
      isFull,
    };
  },
};
</script>
<style lang="scss">
.full-block {
  .blueprint {
    padding-left: 2rem !important;
  }
  .selection {
    @apply w-[200px];
    .chip-icon {
      @apply hidden;
    }
    .control {
      .el-button {
        i + span {
          @apply hidden;
        }
      }
    }
  }
}
</style>

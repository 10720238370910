<template>
  <div class="block">
    <div v-if="!editing">
      <div class="flex items-center py-1">
        <span class="font-bold">
          {{ text }}
        </span>
        <a
          v-if="editable"
          href
          class="cursor-pointer ml-4 w-4 text-xs link whitespace-nowrap"
          @click.prevent="edit"
        >
          編輯名稱
        </a>
      </div>
    </div>
    <div v-else class="flex w-full items-center">
      <input
        class="p-1 font-bold text-base flex-grow"
        type="text"
        v-model="tmpText"
      />

      <a href class="px-2" @click.prevent="done"> <check class="w-4 h-4" /></a>
      <a href class="px-2" @click.prevent="cancel"><close class="w-4 h-4" /></a>
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from 'vue';
import { Check, Close } from '@element-plus/icons';

export default {
  components: { Check, Close },
  props: { text: { type: String }, editable: { type: Boolean, default: true } },
  emits: ['update:text'],
  setup(props, { emit }) {
    const { text } = toRefs(props);

    const editing = ref(false);
    const tmpText = ref('');
    const edit = () => {
      tmpText.value = text.value;
      editing.value = true;
    };
    const done = () => {
      emit('update:text', tmpText.value);

      editing.value = false;
    };

    const cancel = () => {
      editing.value = false;
    };
    return { tmpText, editing, edit, done, cancel };
  },
};
</script>

import { reactive, ref } from 'vue';
import exhibService from '@/services/Exhib';
import { useRoute, useRouter } from 'vue-router';
import { ElMessageBox } from 'element-plus';
import { useStorage } from '@vueuse/core';

export default () => {
  const route = useRoute();

  const router = useRouter();
  const state = reactive({
    id: '',
    title: '',
    status: '',
    isEditable: false,
    // isPublishable: false,
  });
  state.id = route.params.id;

  const fetcher = () => {
    return exhibService.simple({ id: route.params.id }).then((res) => {
      if (res.code !== 1) {
        ElMessageBox.alert('內容不存在', { center: true }).then(() => {
          return router.push({ name: 'Home' });
        });
      } else {
        // state.isEditable =
        //   res.data.status === 'New' || res.data.status === 'Invalid';

        // state.isPreviewable = res.data.status !== 'Published';

        state.isEditable = true;
        state.isPreviewable = true;
        state.title = res.data.title.toString();
        state.status = res.data.status;

        // if (!state.isEditable) {
        //   ElMessageBox.alert('審核中或通過審核之展覽不可以編輯。', {
        //     center: true,
        //     showClose: false,
        //   }).finally(() => {
        //     return router.push({ name: 'Home' });
        //   });
        // } else {
        //   state.title = res.data.title.toString();
        //   state.status = res.data.status;
        // }
      }
    });
  };
  const updateTitle = (title) => {
    exhibService.update({ id: route.params.id, title: title }).then((res) => {
      console.log('done');
    });
  };

  // const setPublishable = (able) => {
  //   state.isPublishable = able;
  // };
  const publish = async () => {
    await ElMessageBox.confirm('確定要送出審核?', { center: true });

    const res = await exhibService.publish({ id: route.params.id });

    if (res.code === 1) {
      await ElMessageBox.alert('資料已送出。', { center: true });
    } else {
      await ElMessageBox.alert('資料庫發生錯誤，請稍後在試。', {
        center: true,
      });
    }
    router.push({ name: 'Member' });
  };

  return {
    state,
    fetcher,
    updateTitle,
    publish,
    // setPublishable,
  };
};

import { reactive, ref, watch } from 'vue';
import SelectionService from '@/services/Selection';
import ExhibService from '@/services/Exhib';
import { useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import _ from 'lodash';

export default ({ id }) => {
  //
  let loading = ref(false);
  let readed = ref(false);
  const data = reactive([]);
  const fetcher = () => {
    loading.value = true;
    return SelectionService.list({ id })
      .then((res) => {
        if (res.code !== 1 || !res.data.items) return;

        res.data.items.forEach((x) => {
          data.push({
            ...x,
          });
        });
      })
      .finally(() => {
        loading.value = false;
        readed.value = true;
      });
  };

  const remove = (item) => {
    if (!item) return;
    loading.value = true;
    return SelectionService.delete({
      id: item.id,
    }).then((res) => {
      const idx = data.findIndex((x) => x.id === item.id);
      if (idx < 0) return;
      data.splice(idx, 1);
      loading.value = false;
      return updateSorter();
    });
  };

  const insert = (item) => {
    loading.value = true;

    return SelectionService.insert({
      ...item,
      exhib_id: id,
      // work_id: item.workId,
      sorter: data.length - 1,
    })
      .then((res) => {
        if (res.code !== 1) return;

        data.push({
          ...res.data,
          sorter: data.length - 1,
        });
        updateSorter();
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const update = (item) => {
    loading.value = true;
    return SelectionService.update({
      ...item,
    })
      .then((res) => {
        if (res.code === 1) {
          const idx = data.findIndex((x) => x.id == res.data.id);
          if (idx > -1) {
            data[idx] = res.data;
          }
        } else {
          ElMessage('發生錯誤');
        }
      })
      .finally(() => {
        loading.value = false;
      });
  };
  const move = (from, to) => {
    const item = _.cloneDeep(data[from]);

    data.splice(from, 1);
    data.splice(to - 1, 0, item);
    return updateSorter();
  };

  const updateSorter = () => {
    const ids = data.map((x) => {
      return x.id;
    });
    loading.value = true;
    return SelectionService.sort({
      id,
      sort: ids,
    })
      .then((res) => {
        data.forEach((x, i) => {
          x.sorter = i + 1;
        });
        return ids;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const clone = ({ fromId, chips }) => {
    SelectionService.clone({
      from_id: fromId,
      to_id: id,
      chips,
    })
      .then((res) => {
        if (res.code === 1) {
          let importItems = _.cloneDeep(res.data.items);

          data.forEach((x) => {
            const idx = importItems.findIndex((y) => y.id === x.id);
            if (idx > -1) {
              x = importItems[idx];
              importItems.splice(idx, 1);
            }
          });
          data.push(...importItems);

          // for (let i = 0; i < data.length; i++) {
          //   const idx = importItems.findIndex((x) => {
          //     return (
          //       // 表示是藏品
          //       parseInt(data[i].work_id) > 0 && data[i].work_id == x.work_id
          //     );
          //   });

          //   if (idx > -1) {
          //     data[i] = {
          //       ...importItems[idx],
          //       id: data[i].id,
          //     };
          //     importItems.splice(idx, 1);
          //   }
          // }

          // data.push(...importItems);

          updateSorter();
        } else {
          ElMessage({ message: '錯誤' });
        }
      })
      .finally(() => {
        loading.value = false;
      });
  };
  const init = () => {
    fetcher();
  };

  return {
    move,
    updateSorter,
    loading,
    readed,
    clone,
    data,
    insert,
    update,
    remove,
    init,
  };
};
